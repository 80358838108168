<template>
  <v-form v-model="valid" ref="forms" @submit.prevent="validate">
    <div
      class="d-flex flex-column align-center"
      :style="{
        width: '500px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }"
    >
      <atoms-title :h2="true">Masuk ke Aplikasi</atoms-title>
      <atoms-text>Hayokerja Admin</atoms-text>

      <div class="mt-2">
        <v-sheet v-if="errorMessage" outlined color="red" rounded="lg" class="mb-2" style="min-width: 100%">
          <v-card outlined elevation="0" rounded="lg" color="accent">
            <v-card-text class="red--text text-start">{{ errorMessage }}</v-card-text>
          </v-card>
        </v-sheet>

        <atoms-text-field
          label="Email"
          v-model="email"
          :rules="[(v) => !v || /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v) || 'Email tidak valid']"
          color="background"
          style="width: 350px"
        />
        <atoms-text-field
          label="Password"
          type="password"
          v-model="password"
          :rules="[(v) => !v || (!!v && v.length >= 8) || 'Minimal terdiri dari 8 karakter']"
          color="background"
          style="width: 350px"
        />

        <atoms-button type="submit" style="width: 100%" class="primary">Lanjutkan</atoms-button>
        <div class="text-center">
          <atoms-text class="my-4"
            ><a :class="$vuetify.theme.dark && 'blue--text'" href="https://www.hayokerja.com/auth/lupa-password">{{
              'Lupa password?'
            }}</a></atoms-text
          >
        </div>
      </div>
    </div>
  </v-form>
</template>
<script>
export default {
  data() {
    return {
      valid: true,
      password: '',
      email: '',
      errorMessage: '',
    }
  },
  methods: {
    async validate() {
      this.$store.commit('SET', { loading: true })
      await this.$refs.forms.validate()
      if (this.valid && this.email && this.password) {
        await this.$store
          .dispatch('user/login', {
            email: this.email,
            password: this.password,
          })
          .then(async () => {
            this.$refs.forms.reset()
            this.$store.commit('SET', { init: true })
            setTimeout(() => this.$router.push('/beranda'), 1000)
          })
          .catch((err) => {
            // console.log("ini", err.response)
            this.password = ''
            this.errorMessage = err?.data?.message || 'Kesalahan'
          })
        this.$refs.forms?.resetValidation()
      }
      this.$store.commit('SET', { loading: false })
    },
  },
}
</script>
<style scoped>
.v-input__slot.background {
  box-shadow: 'rgba(0, 0, 0, 0.05) 0px 0px 1px 1px' !important;
}
</style>
