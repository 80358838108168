var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"forms",on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"d-flex flex-column align-center",style:({
      width: '500px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    })},[_c('atoms-title',{attrs:{"h2":true}},[_vm._v("Masuk ke Aplikasi")]),_c('atoms-text',[_vm._v("Hayokerja Admin")]),_c('div',{staticClass:"mt-2"},[(_vm.errorMessage)?_c('v-sheet',{staticClass:"mb-2",staticStyle:{"min-width":"100%"},attrs:{"outlined":"","color":"red","rounded":"lg"}},[_c('v-card',{attrs:{"outlined":"","elevation":"0","rounded":"lg","color":"accent"}},[_c('v-card-text',{staticClass:"red--text text-start"},[_vm._v(_vm._s(_vm.errorMessage))])],1)],1):_vm._e(),_c('atoms-text-field',{staticStyle:{"width":"350px"},attrs:{"label":"Email","rules":[(v) => !v || /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v) || 'Email tidak valid'],"color":"background"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('atoms-text-field',{staticStyle:{"width":"350px"},attrs:{"label":"Password","type":"password","rules":[(v) => !v || (!!v && v.length >= 8) || 'Minimal terdiri dari 8 karakter'],"color":"background"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('atoms-button',{staticClass:"primary",staticStyle:{"width":"100%"},attrs:{"type":"submit"}},[_vm._v("Lanjutkan")]),_c('div',{staticClass:"text-center"},[_c('atoms-text',{staticClass:"my-4"},[_c('a',{class:_vm.$vuetify.theme.dark && 'blue--text',attrs:{"href":"https://www.hayokerja.com/auth/lupa-password"}},[_vm._v(_vm._s('Lupa password?'))])])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }